import { createContext, useEffect, useState } from 'react'
import jwtDecode from 'jwt-decode';
import { useNavigate } from 'react-router-dom';
const AuthContext = createContext();
export default AuthContext;

export const AuthProvider = ({ children }) => {
    // authTokens used to store and retrieve auth tokens
    const [authTokens, setAuthTokens] = useState(() =>
        localStorage.getItem("authTokens")
            ? JSON.parse(localStorage.getItem("authTokens"))
            : null
    );
    // user used to store and retrieve auth tokens
    const [user, setUser] = useState(() =>
        localStorage.getItem('authTokens')
            ? jwtDecode(localStorage.getItem("authTokens"))
            : null);
    const [loading, setLoading] = useState(true);
    const history = useNavigate();
    const loginUser = async (email, password) => {
        const response = await fetch(
            "http://annaimail.skilltre.in//api/auth/login",
            {
                method: "POST",
                headers: {
                    "Content-Type": "application/json"
                },
                body: JSON.stringify({
                    email,
                    password
                })
            }
        );
        const data = await response.json();
        // alert("check console")
        console.table(response);
        if (response.status === 200) {
            setAuthTokens(data);
            setUser(jwtDecode(data.access));
            localStorage.setItem("authTokens", JSON.stringify(data));
            history("/admin/dashboard");
        } else {
            alert("Something went wrong!");
        }

    };
    const logoutUser = () => {
        setAuthTokens(null);
        setUser(null);
        localStorage.removeItem("authTokens");
        history("/");
    };

    useEffect(() => {
        if (authTokens) {
            setUser(jwtDecode(authTokens.access));
        }
        setLoading(false);
    }, [authTokens, loading]);
    const contextData = {
        user,
        setUser,
        authTokens,
        setAuthTokens,
        loginUser,
        logoutUser
    };
    return (
        <AuthContext.Provider value={contextData}>
            {loading ? null : children}
        </AuthContext.Provider>
    );

}