import React from 'react'
import { Link } from 'react-router-dom'
const LeftSideBar = () => {
    return (
        // {/* start sidebar menu */}
        <div className="sidebar-container">
            <div className="sidemenu-container navbar-collapse collapse fixed-menu">
                <div id="remove-scroll">
                    <ul className="sidemenu page-header-fixed p-t-20" data-keep-expanded="false" data-auto-scroll="true" data-slide-speed={200}>
                        <li className="sidebar-toggler-wrapper hide">
                            <div className="sidebar-toggler">
                                <span />
                            </div>
                        </li>
                        <li className="sidebar-user-panel">
                            <div className="user-panel">
                                <div className="pull-left image">
                                    <img src="../../assets/img/dp.jpg" className="img-circle user-img-circle" alt="your profile " />
                                </div>
                                <div className="pull-left info">
                                    <p>Admin</p>
                                </div>
                            </div>
                        </li>
                        <li className="nav-item">
                            <Link to="/admin/email-contacts" className="nav-link nav-toggle">

                                <i className="material-icons">people</i>
                                <span className="title">Email Contacts</span>

                            </Link>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
        // {/* end sidebar menu */}

    )
}

export default LeftSideBar
