import React from 'react'

const Home = () => {
  return (
  
        <div className="page-content">
          <div className="page-bar">
            <div className="page-title-breadcrumb">
              <div className="pull-left">
                <div className="page-title">Dashboard</div>
              </div>
              <ol className="breadcrumb page-breadcrumb pull-right">
                <li>
                  <i className="fa fa-home" />&nbsp;<a className="parent-item" href="index.html">Home</a>
                </li>
             
              </ol>
            </div>
          </div>
        </div>
  )
}

export default Home
