import { useContext } from "react";
import AuthContext from "../context/AuthContext";
import { useNavigate } from 'react-router-dom';
const LoginPage = () => {
    const { loginUser, user } = useContext(AuthContext);
    const navigate = useNavigate();
    const handleSubmit = e => {
        e.preventDefault();
        const username = e.target.username.value;
        const password = e.target.password.value;
        username.length > 0 && loginUser(username, password);
    };

    return (
        <div className="App">
            {user ? (
                <>
                    {navigate('/admin/dashboard')}
                </>
            ) : (
                // <section>
                //     <form >
                //         <h1>Login </h1>
                //         <hr />
                //         <label htmlFor="email">Email</label>
                //         <input type="text" id="username" placeholder="Enter Username" />
                //         <label htmlFor="password">Password</label>
                //         <input type="password" id="password" placeholder="Enter Password" />
                //         <button type="submit">Login</button>
                //     </form>

                // </section>
                <div class="limiter">
                    <div className="limiter">
                        <div className="container-login100 page-background">
                            <div className="wrap-login100">
                                <form className="login100-form validate-form" onSubmit={handleSubmit}>
                                    <span className="login100-form-logo">
                                        <img alt="" src="../../assets/img/logofinal.png" />
                                    </span>
                                    <span className="login100-form-title p-b-34 p-t-27">
                                        Log in
                                    </span>
                                    <div className="wrap-input100 validate-input" data-validate="Enter Email">
                                        <input className="input100" type="email" name="username" placeholder="Enter Email address" id="username" />
                                        <span className="focus-input100" data-placeholder="&#xf207;"></span>
                                    </div>
                                    <div className="wrap-input100 validate-input" data-validate="Enter password">
                                        <input className="input100" type="password" name="password" placeholder="Password" id="password" />
                                        <span className="focus-input100" data-placeholder="&#xf191;"></span>
                                    </div>
                                    <div className="contact100-form-checkbox">
                                        <input className="input-checkbox100" id="ckb1" type="checkbox" name="remember-me" />
                                        <label className="label-checkbox100" for="ckb1">
                                            Remember me
                                        </label>
                                    </div>
                                    <div className="container-login100-form-btn">
                                        <button className="login100-form-btn" type="submit">
                                            Login
                                        </button>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            )}
        </div>
    );
};

export default LoginPage;