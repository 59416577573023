import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import reportWebVitals from './reportWebVitals';
import 'bootstrap/dist/css/bootstrap.min.css';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import MainLayout from './Layout/MainLayout'
import Home from './Pages/Home'
import { AuthProvider } from './context/AuthContext';
import AuthRoute from './utils/AuthRoute';
import Login from "./utils/loginPage";
import EmailContactsIndex from './Pages/emailContacts/EmailContactsIndex';
const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <BrowserRouter>
      <AuthProvider>
        <Routes>
        <Route path="/admin" element={<AuthRoute />}>
          <Route path='/admin/dashboard' element={<MainLayout><Home /></MainLayout>}></Route>
          <Route path="/admin/email-contacts" element={<MainLayout><EmailContactsIndex /></MainLayout>}></Route>
          </Route>
          <Route path="/" element={<Login />}  />
        </Routes>
      </AuthProvider>
    </BrowserRouter>
  </React.StrictMode>
);

reportWebVitals();
