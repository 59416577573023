import React from 'react'
import { useContext } from "react";
import AuthContext from "../context/AuthContext";
const Header = () => {
    const { logoutUser } = useContext(AuthContext);
    return (
        //   /* start header */
        <div className="page-header navbar navbar-fixed-top">
            <div className="page-header-inner">
                {/* logo start */}
                <div className="page-logo">
                <div>
                        <img alt="" src="../../assets/img/annai-logo.png" />
                        </div>
                        {/* <span className="logo-default">Annai Trading</span> */}
                   
                </div>
                {/* logo end */}
                <ul className="nav navbar-nav navbar-left in">
                    <li>
                        <span  className="menu-toggler sidebar-toggler font-size-23"><i className="fa fa-bars" /></span>
                    </li>
                </ul>
                <ul className="nav navbar-nav navbar-left in">
                    {/* start full screen button */}
                    <li>
                        <span   className="fullscreen-click font-size-20"><i className="fa fa-arrows-alt" /></span>
                    </li>
                    {/* end full screen button */}
                </ul>
                {/* start mobile menu */}
                <a   className="menu-toggler responsive-toggler" data-toggle="collapse" data-target=".navbar-collapse">
                    <span />
                </a>
                {/* end mobile menu */}
                {/* start header menu */}
                <div className="top-menu">
                    <ul className="nav navbar-nav pull-right">

                        {/* start manage user dropdown */}
                        <li className="dropdown dropdown-user">
                            <a   className="dropdown-toggle" data-toggle="dropdown" data-hover="dropdown" data-close-others="true">
                                <img alt="" className="img-circle" src="../../assets/img/dp.jpg" />
                            </a>
                            <ul className="dropdown-menu dropdown-menu-default animated jello">

                                <li>
                                    <button onClick={logoutUser} className="btn btn-sm btn-link">
                                        <i className="fa fa-sign-out" /> Logout</button>


                                </li>
                            </ul>
                        </li>
                        {/* end manage user dropdown */}
              
                    </ul>
                </div>
            </div>
        </div>
        // /* end header */

    )
}

export default Header
