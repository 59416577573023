import React, { useState, useEffect } from 'react'
import Api from '../../helpers/api';
import { useNavigate } from 'react-router-dom';
import { Link } from 'react-router-dom';
const EmailContactsIndex = () => {
    const Api_instance = Api();
    const navigate = useNavigate();
    const [emails, setEmails] = useState([]);
    const [subject, setSubject] = useState('');
    const [bodyMessage, setBodyMessage] = useState('');
    const [checked, setChecked] = useState([]);
    const [checkedAll, setCheckedAll] = useState(true);
    const [is_loading, setIsLoading] = useState(false);
    const [cm_loading, setCMLoading] = useState(false);
    const [is_disabled, setIsDisabled] = useState(false);
    const refreshEmails = () => {
        Api_instance.ContactEmailsGetAll().then(
            (response) => {
                // console.table(response.data);
                setEmails(response.data);
                let updatedList = response.data.map((email) => email.id);
                setChecked(updatedList);
            }
        ).catch((e) => {
            console.log(e)
        });
    }
    useEffect(() => {
        refreshEmails();

    }, []);
    // Add/Remove checked item from list
    const handleCheck = (event) => {
        var updatedList = [...checked];
        if (event.target.checked) {
            updatedList = [...checked, event.target.value];


        } else {
            setCheckedAll(false);
            updatedList.splice(checked.indexOf(event.target.value), 1);
        }
        console.log(updatedList)
        setChecked(updatedList);
        (emails.length === updatedList.length) ? setCheckedAll(true) : setCheckedAll(false);
    };
    const handleCheckAll = (event) => {
        var updatedList = [];
        if (event.target.checked) {
            setCheckedAll(true);
            [...document.querySelectorAll('.boxes')].map((input) => {
                if (!input.checked) {
                    input.checked = !input.checked;
                }
                return null;
            })

            updatedList = emails.map(email => email.id);
        } else {
            setCheckedAll(false);
            [...document.querySelectorAll('.boxes')].map((input) => {
                if (input.checked) {
                    input.checked = !input.checked;
                }
                return null;
            })
            updatedList = [];
        }
        console.log(updatedList)
        setChecked(updatedList);
    }
    const handleBodyMsgChange = (e) => {
        e.preventDefault();
        setBodyMessage(e.target.value);
    };
    const handleSubjectChange = (e) => {
        e.preventDefault();
        setSubject(e.target.value);
    };
    const handleSubmit = async (event) => {
        event.preventDefault();
        setIsLoading(true);
        setIsDisabled(true);
        var data = {
            'subject': subject,
            'bodyMessage': bodyMessage,
            'email_ids': checked,
            'sss': checked,

        };
        Api_instance.ContactEmailSendMsg(data).then(
            (response) => {
                navigate('/admin/dashboard');
                console.log(response);
                setIsLoading(false);
                setIsDisabled(false);
            }
        ).catch((e) => {
            console.log(e);
            setIsLoading(false);
            setIsDisabled(false);
        });


    };
    const MailParsing = () => {
        setIsDisabled(true);
        setCMLoading(true);
        Api_instance.ContactEmailsParsing().then(
            (response) => {
                console.table(response.data);
                // setEmails(response.data);
                // let updatedList = response.data.map((email) => email.id);
                // setChecked(updatedList);
                setIsDisabled(false);
                setCMLoading(false);
            }
        ).catch((e) => {
            console.log(e);
            setIsDisabled(false);
            setCMLoading(false);
        });
    }
    return (

        <div className="page-content">
            <div className="page-bar">
                <div className="page-title-breadcrumb">
                    <div className="pull-left">
                        <div className="page-title">Send Message to Contact Emails </div>
                        <button className="btn btn-sm btn-success m-1" onClick={MailParsing} disabled={is_disabled}>   {cm_loading && (<span className="spinner-grow spinner-grow-sm"></span>)} Download New Emails</button>
                    </div>
                    <ol className="breadcrumb page-breadcrumb pull-right">
                        <li>
                            <i className="fa fa-home" />&nbsp;<Link to="/admin/dashboard" className="parent-item">Home</Link>
                            &nbsp;<i className="fa fa-angle-right"></i>
                            {/* Contact Emails */}
                        </li>
                        <li className="active">Contact Emails</li>
                    </ol>
                </div>
            </div>
            <form onSubmit={handleSubmit} className="todo-form">
                <div className="row">

                    <div className="col-lg-8 col-md-12 col-sm-12 col-12">
                        <div className="card card-box">
                            <div className="card-head">
                                <header>Contact Emails</header>
                            </div>
                            <div className="card-body">
                                <div className="table-wrap">
                                    <div className="table-responsive tblHeightSet small-slimscroll-style">
                                        <table className="table display product-overview mb-30 text-center" id="support_table">
                                            <thead>
                                                <tr>
                                                    <th>
                                                        <div className="dt-checkbox ">
                                                            <input type="checkbox" name="select_all"
                                                                value="1" id="example-select-all" onChange={handleCheckAll} checked={checkedAll} />
                                                            <span className="dt-checkbox-label"></span>
                                                        </div>
                                                    </th>

                                                    <th>Email</th>
                                                    {/* <th>Action</th> */}
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {emails.map((email) => (
                                                    <tr key={email.id}>
                                                        <td><input type="checkbox" name="emails[]" value={email.id} className="boxes" onChange={handleCheck} defaultChecked={true} /></td>
                                                        <td>{email.address}</td>
                                                    </tr>
                                                ))}

                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-4 col-md-12 col-sm-12 col-12">
                        <div className="card card-box">
                            <div className="card-head">
                                <header>Message </header>
                            </div>
                            <div className="card-body">
                                <div className="row">
                                    <div className="details">
                                        <div className="title">
                                            <div className="form-group">
                                                <label>Subject</label>
                                                <input type="text" className="form-control" placeholder="Enter Subject .." name='subject' value={subject} onChange={handleSubjectChange} />
                                            </div>
                                        </div>
                                        <div>
                                            <div className="form-group">
                                                <label>Body</label>
                                                <textarea className="form-control" rows="10" placeholder="Enter ..." name='body' value={bodyMessage} onChange={handleBodyMsgChange} ></textarea>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-lg-6 col-md-6 text-end">
                            <button type="submit" className="btn btn-primary btn-md" disabled={is_disabled}>
                                {is_loading && (<span className="spinner-grow spinner-grow-sm"></span>)}  Send
                            </button>
                        </div>

                    </div>


                </div>
            </form>
        </div>


    )
}

export default EmailContactsIndex
